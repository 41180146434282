import React, { useState } from 'react';

const Contact = () => {
  const [menuOpen, setMenuOpen] = useState(false);


  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <header className="bg-gradient-to-b from-gray-600 via-gray-460 to-white text-white h-screen flex items-start relative z-9999">
   
      <nav className="flex flex-row items-center gap-10 mt-[5%] ml-[10%] whitespace-nowrap">
        <a
          href="#projects"
          className="hover-underline-animation text-xl font-bold sm:text-[20px] md:text-[20px] lg:text-[20px] xl:text-[25px]"
          onClick={(e) => {
            e.preventDefault();
            scrollToSection('projects');
          }}
        >
          Lavori
        </a>
        <a
          href="#about"
          className="hover-underline-animation text-xl ssm:left-[-95px] ssm:top-[50px] font-bold sm:text-[20px] md:text-[20px] lg:text-[20px] xl:text-[25px] white-space-nowrap"
          onClick={(e) => {
            e.preventDefault();
            scrollToSection('about');
          }}
        >
          Chi sono
        </a>
        <a
          href="#contact"
          className="hover-underline-animation text-xl font-bold ssm:left-[-215px] ssm:top-[100px] sm:text-[20px] md:text-[20px] lg:text-[20px] xl:text-[25px]"
          onClick={(e) => {
            e.preventDefault();
            scrollToSection('contact');
          }}
        >
          Contattami
        </a>
      </nav>
      <a href="#" className="absolute text-xl font-kaushan left-auto right-10 top-5 cursore-sopra">
        Marok
      </a>

     

      <h1
        className="absolute top-[50%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 
        text-[80px] sm:text-[100px] md:text-[150px] lg:text-[200px] xl:text-[250px] font-bebas"
      >
        <span className="cursore-sopra">C</span>
        <span className="cursore-sopra">i</span>
        <span className="cursore-sopra">a</span>
        <span className="cursore-sopra">o</span>
        <span className="cursore-sopra">!</span>
      </h1>
      <div
        className="absolute top-[80%] left-[46%] w-[40px] h-[40px] border-t-[2px] 
        border-r-[2px] border-black transform rotate-[135deg] cursor-pointer sm:left-[49%] h-560:hidden"
        onClick={() => scrollToSection('projects')}
      />

    </header>
  );
};

export default Contact;

import React, { useState } from 'react';

const Footer = () => {
 

  return (
    <section id="contact" className="bg-c2 text-black min-h-[200px] flex flex-col items-center justify-center">
        
        <div class="container mx-auto flex justify-between items-center px-[5%]">
   
          <span class="text-xl font-semibold left-0 font-bebas"><span className="font-kaushan">Marok</span> @ 2024

          </span>

          <div class="flex space-x-4 text-lg font-light">
            <a href="https://www.instagram.com/motalik_" target="_blank" class="hover-underline-animation-nero font-bebas">Instagram</a>
            <a href="https://www.github.com/motalik" target="_blank" class="hover-underline-animation-nero font-bebas">GitHub</a>
          </div>
        </div>


    </section>


  );
};

export default Footer;


import Header from './components/Header.jsx';
import About from './components/About.jsx';
import Projects from './components/Projects.jsx';
import Contact from './components/Contact.jsx';
import Footer from './components/Footer.jsx';




const App = () => {
  
  return (
    <div>
      <Header />
      <Projects />
      <About />
      <Contact />
      <Footer />
    </div>
  );
};

export default App;

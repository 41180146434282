import React, { useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';

const Projects = () => {
  const [showRiquadro, setShowRiquadro] = useState(false);

  const scrollToAbout = () => {
    const aboutSection = document.getElementById('about');
    if (aboutSection) {
      aboutSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const toggleRiquadro = () => {
    setShowRiquadro(!showRiquadro);
  };

  const redirectToWebsite = () => {
    window.open("https://www.google.com", "_blank");
  };

  return (
    <section id="projects" className="bg-white text-black min-h-screen flex flex-col items-center justify-center relative">
      <div className="flex flex-col items-center mt-[-600px] md:mt-[0%] md:ml-[-60%] hover:cursor-default ">
        <h2 className="mt-[70px] text-[60px] lg:text-[100px] font-bold mb-4 font-kaushan transform h-706:text-[40px] h-706:mt-[200px] h-430:mt-[100px]">Lavori</h2>
        <p className='text-center mb-10 md:max-w-[200px] max-w-[900px] lg:max-w-[500px] '>
          Programmare questi siti ha permesso di trasformare
          <br /> le mie idee in qualcosa di concreto
        </p>
      </div>

      <div 
        className='absolute mt-[50px] w-[50vw] h-[40vh] bg-secondary flex flex-col justify-center rounded-lg 
                    shadow-[0_35px_60px_-15px_rgba(0,0,0,1)] transform transition-all duration-200 ease-in-out 
                    hover:scale-105 md:ml-[30%] md:h-[60vh] md:mt-[-60px]' 
        onClick={redirectToWebsite}
      >
        <FaInfoCircle 
          className="absolute top-2 right-2 text-white size-10 cursor-pointer" 
          onClick={(e) => {
            e.stopPropagation();
            toggleRiquadro();
          }}
        />
        <img src="../allmath.png" alt="math" className="max-w-[600px] lg:h-full rounded-lg" />
      </div>

      {showRiquadro && (
        <div 
          id="riquadro" 
          className='absolute top-[0%] left-[0%] w-[300px] p-6 bg-gradient-to-r from-gray-100 to-gray-300 border border-gray-300 rounded-xl 
                      shadow-lg transform transition-all duration-300 ease-in-out hover:scale-105 hover:shadow-2xl
                      flex flex-col items-center justify-center' onClick={toggleRiquadro}
        >
          <h3 className="font-bold text-lg mb-2 text-gray-700">AllMath Project</h3>
          <p className="text-gray-600 text-center">
            Sito web di matematica che fornisce risorse e strumenti per studiare/approfondire 
            concetti matematici.
          </p>
        </div>
      )}

      <div
        className="absolute left-[46%] top-[80%] w-[40px] h-[40px] border-t-[2px] border-r-[2px] border-black 
        transform rotate-[135deg] cursor-pointer sm:left-[49%] h-560:hidden" 
        onClick={scrollToAbout} 
      ></div>
    </section>
  );
};

export default Projects;
import React from 'react';
import { FaGithub } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

const Contact = () => {
  return (
    <section id="contact" className="bg-white text-black min-h-screen flex flex-col items-center justify-center">
      <div className='flex flex-col items-center  mt-[-170px]'>
        <h1 className='font-lilita text-[200px] llg:text-[100px] otc:text-[50px] mb-[30px]'>Contattami:</h1>
        <a href="mailto:alessandromarocco060@gmail.com">
          <span className="text-[17px] mt-5 underline font-kaushan qcc:text-[30px] md:text-[50px]">
            alessandromarocco060@gmail.com
          </span>
        </a>
      </div>

      <div className="absolute mt-[400px] flex  space-x-20 lg:mt-[700px]">
        <a href="https://github.com/motalik" target="_blank" rel="noopener noreferrer">
          <FaGithub size={80} className="text-black hover:text-gray-700 transition duration-300" />
        </a>
        <a href="https://instagram.com/motalik_" target="_blank" rel="noopener noreferrer">
          <FaInstagram size={80} className="text-black hover:text-gray-700 transition duration-300" />
        </a>
      </div>
    </section>
  );
};

export default Contact;
